var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"blue darken-2"}},[_c('v-toolbar-title',[_vm._v("收支明細")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"fab":"","plain":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" fas fa-times")])],1)],1)],1),_c('v-card-title'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableDetailHeaders,"items":_vm.details,"options":_vm.pagination,"server-items-length":_vm.totalDetails,"loading":_vm.isDetailLoading,"loading-text":_vm.$t("common['Loading... Please wait']"),"no-data-text":_vm.$t("common['No Data']"),"no-results-text":_vm.$t("common['No Data']"),"footer-props":{
        'items-per-page-text': _vm.$t("common['Rows per page']"),
      },"page":_vm.pagination.page,"hide-default-footer":"","items-per-page":_vm.pagination.itemsPerPage},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.transactionType",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t(("transactions." + (item.transactionType))))+" ")])]}},{key:"item.income",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.income, 0)))])]}},{key:"item.expenditure",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.expenditure, 0)))])]}},{key:"item.balance",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNumber(item.balance, 0)))])]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdAt ? _vm.moment.unix(item.createdAt).format("YYYY/MM/DD HH:mm:ss") : ""))])]}}])})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('Pagination',{attrs:{"pagination":_vm.pagination,"total":_vm.totalDetails,"loading":_vm.isDetailLoading},on:{"update":_vm.getDataFromApi}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }