<template>
  <v-card>
    <v-card-title>
      <span class="headline">
        預算轉讓：{{ transferFrom && transferFrom.name }}
      </span>
      <span class="mx-2 subtitle">
        （剩餘點數：{{ formatNumber(transferFrom.availablePoints, 0) }}）
      </span>
    </v-card-title>
    <v-card-subtitle class="pt-2">
      <span class="subtitle font-weight-light"> 請選擇要轉讓的預算 </span>
    </v-card-subtitle>
    <v-card-text>
      <v-container>
        <v-form ref="transferForm">
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="transferredBudgetId"
                :items="availableTransferBudgets"
                item-text="budgetName"
                item-value="id"
                :label="$t(`budgets['Name']`)"
                :disabled="isProcessing"
                :no-data-text="$t(`common['No Data']`)"
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="number"
                v-model="transferFrom.transferPoints"
                label="轉讓點數"
                :disabled="isProcessing"
                :rules="[
                  rules.greaterThanZero,
                  rules.maxTransfer(
                    transferFrom.availablePoints,
                    transferFrom.transferPoints
                  ),
                ]"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isProcessing"
        @click="onCloseTransferDialog"
      >
        {{ $t(`common['Cancel']`) }}
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :disabled="isProcessing"
        @click="onTransferBudget"
      >
        {{ $t(`common['Save']`) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { pointAssignment } from "src/apis/pointsCore";
import { formatNumber } from "src/util/utils";

export default {
  name: "BudgetTransfer",
  components: {},
  props: {
    availableTransferBudgets: {
      type: Array,
      default: function () {
        return [];
      },
      required: true,
    },
    entityType: {
      type: String,
      default: "",
      required: true,
    },
    giver: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      formatNumber: formatNumber,
      isProcessing: false,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
        maxTransfer(maxValue, v) {
          // console.log(maxValue, v, (v || "") <= maxValue);
          return (v || "") <= maxValue || `轉讓點數最多為${maxValue}`;
        },
        greaterThanZero(v) {
          return v > 0 || `轉讓點數需大於0`;
        },
      },
      transferredBudgetId: null,
      transferFrom: {},
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
    // transferFrom() {
    //   return Object.assign({}, this.giver);
    // },
  },
  watch: {
    // transferFrom: {
    //   handler(newVal) {
    //   },
    //   deep: true,
    // },
    giver: {
      handler(newVal) {
        this.transferFrom = Object.assign({}, newVal);
      },
      deep: true,
    },
  },
  created() {
    this.transferFrom = Object.assign({}, this.giver);
  },
  mounted() {},
  methods: {
    onCloseTransferDialog() {
      this.$emit("close");
    },
    onTransferBudget() {
      // api to tranasfer budget
      // const giver = this.tableData.filter((i) => i.id === this.transferFrom.id);
      const receiver = this.availableTransferBudgets.filter(
        (i) => i.id === this.transferredBudgetId
      );
      console.log("receiver:", receiver);
      const valid = this.$refs.transferForm.validate();
      if (valid) {
        const data = {
          giverEntityType: this.entityType,
          giverEntityId: this.transferFrom.id.toString(),
          receiverEntityType: this.entityType,
          receiverEntityId: this.transferredBudgetId.toString(),
          points: this.transferFrom.transferPoints,
          note: `預算轉讓：${this.transferFrom.name} 轉給 ${receiver[0].budgetName}`,
        };
        console.log("data:", data);
        this.$swal({
          title: "預算轉讓",
          html: `是否確定轉讓點數<b>
          ${this.formatNumber(
            Number(this.transferFrom.transferPoints),
            0
          )}</b><br/>給<br/>${receiver[0].budgetName}？`,
          type: "info",
          showCancelButton: true,
          confirmButtonText: this.$i18n.t(`common["Confirm"]`),
          cancelButtonText: this.$i18n.t(`common["Cancel"]`),
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
        }).then((result) => {
          // console.log("result", result);
          if (result.isConfirmed) {
            pointAssignment(this.merchantId, data)
              .then((res) => {
                console.log("pointAssignment done", res);
                this.$emit("done");
              })
              .catch((e) => {
                console.log("pointAssignment failed", e);
                let errmsg = `${this.transferFrom.name}<br/>到<br/>${receiver[0].budgetName}<br/>轉讓失敗`;
                if (
                  e.response &&
                  e.response.data &&
                  e.response.data.error &&
                  e.response.data.error.message
                ) {
                  errmsg += "<br/>" + e.response.data.error.message;
                }
                this.showErrorAlert(errmsg);
              })
              .finally(() => {
                this.isProcessing = false;
                // this.onCloseTransferDialog();
              });
          }
        });
      } else {
        //this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`), "");
      }
    },
    showErrorAlert(message) {
      let title = "轉讓失敗";
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
</style>
