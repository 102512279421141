<template>
  <v-card>
    <v-toolbar dark color="blue darken-2">
      <v-toolbar-title>收支明細</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn fab plain @click="close">
          <v-icon> fas fa-times</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-title> </v-card-title>
    <!-- <v-card-title>
      收支明細
      <v-spacer></v-spacer>
     
      <v-btn fab plain @click="close">
        <v-icon> fas fa-times</v-icon>
      </v-btn>
    </v-card-title> -->
    <v-card-text>
      <v-data-table
        :headers="tableDetailHeaders"
        :items="details"
        :options.sync="pagination"
        :server-items-length="totalDetails"
        :loading="isDetailLoading"
        :loading-text="$t(`common['Loading... Please wait']`)"
        :no-data-text="$t(`common['No Data']`)"
        :no-results-text="$t(`common['No Data']`)"
        :footer-props="{
          'items-per-page-text': $t(`common['Rows per page']`),
        }"
        :page.sync="pagination.page"
        hide-default-footer
        @page-count="pagination.pageCount = $event"
        :items-per-page="pagination.itemsPerPage"
      >
        <template v-slot:item.transactionType="{ item }">
          <span>
            {{ $t(`transactions.${item.transactionType}`) }}
          </span>
        </template>
        <template v-slot:item.income="{ item }">
          <span>{{ formatNumber(item.income, 0) }}</span>
        </template>
        <template v-slot:item.expenditure="{ item }">
          <span>{{ formatNumber(item.expenditure, 0) }}</span>
        </template>
        <template v-slot:item.balance="{ item }">
          <span>{{ formatNumber(item.balance, 0) }}</span>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span>{{
            item.createdAt
              ? moment.unix(item.createdAt).format("YYYY/MM/DD HH:mm:ss")
              : ""
          }}</span>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="card-padding">
      <Pagination
        :pagination="pagination"
        :total="totalDetails"
        :loading="isDetailLoading"
        @update="getDataFromApi"
      ></Pagination>
    </v-card-actions>
    <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" dark @click="openDetailDialog = false">
              Close
            </v-btn>
          </v-card-actions> -->
  </v-card>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { formatNumber } from "src/util/utils";

import {
  fetchAnnualBudgetDetail,
  fetchDepartmentBudgetDetail,
  fetchCampaignBudgetDetail,
} from "src/apis/fetchData";
import Pagination from "../../Campaigns/Widgets/Pagination.vue";

export default {
  name: "Ledger",
  components: { Pagination },
  props: {
    entityType: {
      type: String,
      default: null,
      required: true,
    },
    budgetId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      isDetailLoading: false,
      totalDetails: 0,
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      details: [],
      tableDetailHeaders: [
        {
          text: "時間",
          sortable: true,
          filterable: false,
          value: "createdAt",
        },
        {
          text: "摘要",
          sortable: false,
          filterable: false,
          value: "transactionType",
        },
        {
          text: "收入",
          sortable: false,
          filterable: false,
          align: "right",
          value: "income",
        },
        {
          text: "支出",
          sortable: false,
          filterable: false,
          align: "right",
          value: "expenditure",
        },
        {
          text: "餘額",
          sortable: false,
          filterable: false,
          align: "right",
          value: "balance",
        },
        {
          text: "備註",
          sortable: false,
          filterable: false,
          value: "description",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
    departmentId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.department : null;
    },
  },
  watch: {
    // pagination: {
    //   handler() {
    //     this.getDataFromApi();
    //   },
    //   deep: true,
    // },
    budgetId: {
      handler() {
        this.getDataFromApi();
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      this.details = [];
      this.totalDetails = 0;
      this.$emit("close");
    },
    getDataFromApi() {
      this.isDetailLoading = true;
      if (!this.openDetailDialog) {
        this.openDetailDialog = true;
      }
      this.getBudgetDetail().then((data) => {
        this.details = [...data.items];
        this.totalDetails = data.total;
        this.isDetailLoading = false;
      });
    },
    getBudgetDetail() {
      this.details = [];
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
        // console.log("pagination", this.pagination);
        let query = {
          offset: (page - 1) * itemsPerPage || 0,
          limit: itemsPerPage,
        };
        let items = [];
        let total = 0;
        let getBudgetLedger = fetchAnnualBudgetDetail;
        // let org_dept_id = this.organizationId;
        switch (this.entityType) {
          case "DB":
            getBudgetLedger = fetchDepartmentBudgetDetail;
            // org_dept_id = this.departmentId;
            break;
          case "CB":
            getBudgetLedger = fetchCampaignBudgetDetail;
            // org_dept_id = this.departmentId;
            break;
          case "AB":
          default:
            break;
        }
        getBudgetLedger(this.merchantId, this.budgetId, query)
          .then((res) => {
            console.log("fetchBudgetDetail done:" + JSON.stringify(res));
            items = [...res.data.details];
            total = res.data.total;
          })
          .catch((e) => {
            console.log("fetchBudgetDetail failed", e);
            this.$swal({
              text: "無法取得期別預算帳本資料",
              type: "error",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          })
          .finally(() => {
            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }

            // if (itemsPerPage > 0) {
            //   items = items.slice(
            //     (page - 1) * itemsPerPage,
            //     page * itemsPerPage
            //   );
            // }

            // if (page > 1) {
            //   let rawBegin = this.details.slice(0, (page - 1) * itemsPerPage);
            //   let rawEnd = this.details.slice(page * itemsPerPage, -1);
            //   this.items = rawBegin.concat(items, rawEnd);
            // }
            setTimeout(() => {
              resolve({
                items,
                total,
              });
            }, 1000);
          });
      });
    },
    showErrorAlert(message) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
/* .radio-large::v-deep .v-icon {
  font-size: "24px !important";
} */
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
/* .large-radio::v-deep [class*="__ripple"] {
  left: 0;
} */
</style>
